<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="columns">
        <div class="column is-2">
          <b-datepicker
            v-model="fromdate"
            placeholder="ຈາກວັນທີ"
            :locale="this.getDateLocale"
            icon="calendar-today"
          >
          </b-datepicker>
        </div>
        <div class="column is-2">
          <b-datepicker
            v-model="todate"
            :locale="this.getDateLocale"
            placeholder="ຫາວັນທີ"
            icon="calendar-today"
          >
          </b-datepicker>
        </div>
        <div class="column is-1">
          <button class="button" @click="refreshData">
            <b-icon icon="magnify" />
          </button>
        </div>
        <div class="column is-3 has-text-left" v-if="models.shipment && models.shipment.length > 0">
          <b-button @click="generateExcelFile" size="is-medium">
            <b-icon
              icon="file-excel-outline"
              style="margin-right: 0.5em"
            ></b-icon>
            ດາວໂຫລດເປັນ Excel
          </b-button>
        </div>
        <div class="column is-2">
          <router-link
            class="button is-medium"
            :to="'/batchshipmentform/' + models.tel"
          >
            ສ້າງບິນຮັບຝາກເຄື່ອງເປັນກຸ່ມ
          </router-link>
        </div>
      </div>
    </section>
    <b-tabs type="is-boxed" position="is-centered">
      <b-tab-item>
        <template #header>
          <b-icon icon="information-outline"></b-icon>
          <span
            >ຂໍ້ມູນລົງທະບຽນ<b-tag rounded>{{ models.tel }}</b-tag>
          </span>
        </template>
        <div>
          <section style="padding: 1em">
            <b-field label="" horizontal>
              <figure class="image is-128x128">
                <b-image
                  rounded
                  ratio="1by1"
                  class="is-rounded"
                  :src="userImage"
                  style="
                    max-height: 128px !important;
                    max-width: 128px !important;
                  "
                  :src-fallback="require('@/assets/logo_color.png')"
                  :placeholder="require('@/assets/logo_color.png')"
                >
                </b-image>
              </figure>
            </b-field>
            <b-field label="ລະຫັດ" horizontal>
              <span>{{ models.code }}</span>
            </b-field>
            <b-field label="ຊື່" horizontal>
              <span>{{ models.name }}</span>
            </b-field>
            <b-field label="ເບີໂທ" horizontal>
              <span>{{ models.tel }}</span>
            </b-field>
            <b-field label="ບ້ານ" horizontal>
              <span>{{ models.village }}</span>
            </b-field>
            <b-field label="ເມືອງ" horizontal>
              <span>{{ models.district === null ? "-": models.district.name }}</span>
            </b-field>
            <b-field label="ແຂວງ" horizontal>
              <span>{{ models.district === null ? "-": models.district.province.name }}</span>
            </b-field>
          </section>
        </div>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <b-icon icon="source-pull"></b-icon>
          <span>
            ລາຍການເຄື່ອງຝາກ <b-tag rounded> {{ countShipment }} </b-tag>
          </span>
        </template>
        <b-tabs>
          <b-tab-item>
            <template #header>
              <b-icon icon="creation"></b-icon>
              <span>
                ກຳລັງສ້າງ <b-tag rounded> {{ shipmentCREATED.length }} </b-tag>
              </span>
            </template>
            <b-table
              :loading="isLoading"
              :paginated="perPage < shipmentCREATED.length"
              :per-page="perPage"
              :striped="true"
              :hoverable="true"
              default-sort="name"
              :narrowed="true" class="is-narrow is-size-7" style="white-space: nowrap;"
              :data="shipmentCREATED"
              :current-page="currentPage"
              :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index * currentPage + 1 }}
              </b-table-column>
              <b-table-column
                label="ວັນທີ"
                field="created_date"
                sortable
                v-slot="props"
              >
                {{ props.row.created_date | datetime }}
              </b-table-column>
              <b-table-column
                label="ລະຫັດ"
                field="code"
                sortable
                v-slot="props"
              >
                <router-link :to="'/shipment/' + props.row.code">
                  {{ props.row.code }}
                </router-link>
              </b-table-column>
              <b-table-column
                label="ຜູ້ຮັບ"
                field="receivername"
                sortable
                v-slot="props"
              >
                {{ props.row.receivername }}
              </b-table-column>
              <b-table-column
                label="ສາຂາຕົ້ນທາງ"
                field="branch_branchToshipment_from_branch_id.name "
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_from_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ສາຂາປາຍທາງ"
                field="branch_branchToshipment_to_branch_id.name"
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_to_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ຈຳນວນເງິນ"
                field="amount"
                sortable
                v-slot="props"
                cell-class="has-text-right"
              >
                {{ props.row.amount | formatnumber }}
              </b-table-column>
              <b-table-column
                label="ສະຖານະ"
                field="status"
                sortable
                v-slot="props"
                cell-class="has-text-centered"
              >
                <shipment-status :status="props.row.status" />
              </b-table-column>
              <b-table-column
                label="ໝາຍເຫດ"
                field="codremark"
                v-slot="props"
                class="is-size-7"
              >
                {{ props.row.codremark }}
              </b-table-column>
              <empty-table
                v-if="shipmentCREATED.length == 0"
                :isLoading="isLoading"
              />
            </b-table>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <b-icon icon="cube-send"></b-icon>
              <span>
                ກຳລັງຈັດສົ່ງ
                <b-tag rounded> {{ shipmentSENDTED.length }} </b-tag>
              </span>
            </template>
            <b-table
              :loading="isLoading"
              :paginated="perPage < shipmentSENDTED.length"
              :per-page="perPage"
              :striped="true"
              :hoverable="true"
              default-sort="name"
              :narrowed="true" class="is-narrow is-size-7" style="white-space: nowrap;"
              :data="shipmentSENDTED"
              :current-page="currentPage"
              :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index * currentPage + 1 }}
              </b-table-column>
              <b-table-column
                label="ວັນທີ"
                field="created_date"
                sortable
                v-slot="props"
              >
                {{ props.row.created_date | datetime }}
              </b-table-column>
              <b-table-column
                label="ລະຫັດ"
                field="code"
                sortable
                v-slot="props"
              >
                <router-link :to="'/shipment/' + props.row.code">
                  {{ props.row.code }}
                </router-link>
              </b-table-column>
              <b-table-column
                label="ຜູ້ຮັບ"
                field="receivername"
                sortable
                v-slot="props"
              >
                {{ props.row.receivername }}
              </b-table-column>
              <b-table-column
                label="ສາຂາຕົ້ນທາງ"
                field="branch_branchToshipment_from_branch_id.name "
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_from_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ສາຂາປາຍທາງ"
                field="branch_branchToshipment_to_branch_id.name"
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_to_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ຈຳນວນເງິນ"
                field="amount"
                sortable
                v-slot="props"
                cell-class="has-text-right"
              >
                {{ props.row.amount | formatnumber }}
              </b-table-column>
              <b-table-column
                label="ສະຖານະ"
                field="status"
                sortable
                v-slot="props"
                cell-class="has-text-centered"
              >
                <shipment-status :status="props.row.status" />
              </b-table-column>
              <b-table-column
                label="ໝາຍເຫດ"
                field="codremark"
                v-slot="props"
                class="is-size-7"
              >
                {{ props.row.codremark }}
              </b-table-column>
              <empty-table
                v-if="shipmentSENDTED.length == 0"
                :isLoading="isLoading"
              />
            </b-table>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <b-icon icon="check-all"></b-icon>
              <span>
                ຈັດສົ່ງສຳເລັດ
                <b-tag rounded> {{ shipmentDELIVERED.length }} </b-tag>
              </span>
            </template>
            <b-table
              :loading="isLoading"
              :paginated="perPage < shipmentDELIVERED.length"
              :per-page="perPage"
              :striped="true"
              :hoverable="true"
              default-sort="name"
              :narrowed="true" class="is-narrow is-size-7" style="white-space: nowrap;"
              :data="shipmentDELIVERED"
              :current-page="currentPage"
              :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index * currentPage + 1 }}
              </b-table-column>
              <b-table-column
                label="ວັນທີ"
                field="created_date"
                sortable
                v-slot="props"
              >
                {{ props.row.created_date | datetime }}
              </b-table-column>
              <b-table-column
                label="ລະຫັດ"
                field="code"
                sortable
                v-slot="props"
              >
                <router-link :to="'/shipment/' + props.row.code">
                  {{ props.row.code }}
                </router-link>
              </b-table-column>
              <b-table-column
                label="ຜູ້ຮັບ"
                field="receivername"
                sortable
                v-slot="props"
              >
                {{ props.row.receivername }}
              </b-table-column>
              <b-table-column
                label="ສາຂາຕົ້ນທາງ"
                field="branch_branchToshipment_from_branch_id.name "
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_from_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ສາຂາປາຍທາງ"
                field="branch_branchToshipment_to_branch_id.name"
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_to_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ຈຳນວນເງິນ"
                field="amount"
                sortable
                v-slot="props"
                cell-class="has-text-right"
              >
                {{ props.row.amount | formatnumber }}
              </b-table-column>
              <b-table-column
                label="ສະຖານະ"
                field="status"
                sortable
                v-slot="props"
                cell-class="has-text-centered"
              >
                <shipment-status :status="props.row.status" />
              </b-table-column>
              <b-table-column
                label="ໝາຍເຫດ"
                field="codremark"
                v-slot="props"
                class="is-size-7"
              >
                {{ props.row.codremark }}
              </b-table-column>
              <empty-table
                v-if="shipmentDELIVERED.length == 0"
                :isLoading="isLoading"
              />
            </b-table>
          </b-tab-item>
        </b-tabs>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <b-icon icon="cash-marker"></b-icon>
          <span>
            ລາຍການເຄື່ອງຝາກ COD <b-tag rounded> {{ countCOD }} </b-tag>
          </span>
        </template>
        <b-tabs>
          <b-tab-item>
            <template #header>
              <b-icon icon="creation"></b-icon>
              <span>
                ກຳລັງສ້າງ <b-tag rounded> {{ codCREATED.length }} </b-tag>
              </span>
            </template>
            <b-table
              :loading="isLoading"
              :paginated="perPage < models.length"
              :per-page="perPage"
              :striped="true"
              :hoverable="true"
              default-sort="name"
              :narrowed="true" class="is-narrow is-size-7" style="white-space: nowrap;"
              :data="codCREATED"
              :current-page="currentPage"
              :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index * currentPage + 1 }}
              </b-table-column>
              <b-table-column
                label="ວັນທີ"
                field="created_date"
                sortable
                v-slot="props"
              >
                {{ props.row.created_date | datetime }}
              </b-table-column>
              <b-table-column
                label="ລະຫັດ"
                field="code"
                sortable
                v-slot="props"
              >
                <router-link :to="'/shipment/' + props.row.code">
                  {{ props.row.code }}
                </router-link>
              </b-table-column>
              <b-table-column
                label="ຜູ້ຮັບ"
                field="receivername"
                sortable
                v-slot="props"
              >
                {{ props.row.receivername }}
              </b-table-column>
              <b-table-column
                label="ສາຂາຕົ້ນທາງ"
                field="branch_branchToshipment_from_branch_id.name "
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_from_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ສາຂາປາຍທາງ"
                field="branch_branchToshipment_to_branch_id.name"
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_to_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ຄ່າຝາກ"
                field="amount"
                sortable
                v-slot="props"
              >
                {{ props.row.amount | formatnumber }}
              </b-table-column>
              <b-table-column label="COD" sortable v-slot="props">
                {{ formattedCod(props.row) }}
              </b-table-column>
              <b-table-column
                label="ສະຖານະ"
                field="status"
                sortable
                v-slot="props"
                cell-class="has-text-centered"
              >
                <shipment-status :status="props.row.status" />
              </b-table-column>
              <b-table-column
                label="ໝາຍເຫດ"
                field="codremark"
                v-slot="props"
                class="is-size-7"
              >
                {{ props.row.codremark }}
              </b-table-column>

              <empty-table
                v-if="codCREATED.length == 0"
                :isLoading="isLoading"
              />
            </b-table>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <b-icon icon="cube-send"></b-icon>
              <span>
                ກຳລັງຈັດສົ່ງ <b-tag rounded> {{ codSENDTED.length }} </b-tag>
              </span>
            </template>
            <b-table
              :loading="isLoading"
              :paginated="perPage < codSENDTED.length"
              :per-page="perPage"
              :striped="true"
              :hoverable="true"
              default-sort="name"
              :narrowed="true" class="is-narrow is-size-7" style="white-space: nowrap;"
              :data="codSENDTED"
              :current-page="currentPage"
              :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index * currentPage + 1 }}
              </b-table-column>
              <b-table-column
                label="ວັນທີ"
                field="created_date"
                sortable
                v-slot="props"
              >
                {{ props.row.created_date | datetime }}
              </b-table-column>
              <b-table-column
                label="ລະຫັດ"
                field="code"
                sortable
                v-slot="props"
              >
                <router-link :to="'/shipment/' + props.row.code">
                  {{ props.row.code }}
                </router-link>
              </b-table-column>
              <b-table-column
                label="ຜູ້ຮັບ"
                field="receivername"
                sortable
                v-slot="props"
              >
                {{ props.row.receivername }}
              </b-table-column>
              <b-table-column
                label="ສາຂາຕົ້ນທາງ"
                field="branch_branchToshipment_from_branch_id.name "
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_from_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ສາຂາປາຍທາງ"
                field="branch_branchToshipment_to_branch_id.name"
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_to_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ຄ່າຝາກ"
                field="amount"
                sortable
                v-slot="props"
              >
                {{ props.row.amount | formatnumber }}
              </b-table-column>
              <b-table-column label="COD" sortable v-slot="props">
                {{ formattedCod(props.row) }}
              </b-table-column>
              <b-table-column
                label="ສະຖານະ"
                field="status"
                sortable
                v-slot="props"
                cell-class="has-text-centered"
              >
                <shipment-status :status="props.row.status" />
              </b-table-column>
              <b-table-column
                label="ໝາຍເຫດ"
                field="codremark"
                v-slot="props"
                class="is-size-7"
              >
                {{ props.row.codremark }}
              </b-table-column>
              <empty-table
                v-if="codSENDTED.length == 0"
                :isLoading="isLoading"
              />
            </b-table>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <b-icon icon="check-all"></b-icon>
              <span>
                ຈັດສົ່ງສຳເລັດ <b-tag rounded> {{ codDELIVERED.length }} </b-tag>
              </span>
            </template>
            <b-table
              :loading="isLoading"
              :paginated="perPage < codDELIVERED.length"
              :per-page="perPage"
              :striped="true"
              :hoverable="true"
              default-sort="name"
              :narrowed="true" class="is-narrow is-size-7" style="white-space: nowrap;"
              :data="codDELIVERED"
              :current-page="currentPage"
              :pagination-rounded="true"
            >
              <b-table-column label="#" v-slot="props">
                {{ props.index * currentPage + 1 }}
              </b-table-column>
              <b-table-column
                label="ວັນທີ"
                field="created_date"
                sortable
                v-slot="props"
              >
                {{ props.row.created_date | datetime }}
              </b-table-column>
              <b-table-column
                label="ລະຫັດ"
                field="code"
                sortable
                v-slot="props"
              >
                <router-link :to="'/shipment/' + props.row.code">
                  {{ props.row.code }}
                </router-link>
              </b-table-column>
              <b-table-column
                label="ຜູ້ຮັບ"
                field="receivername"
                sortable
                v-slot="props"
              >
                {{ props.row.receivername }}
              </b-table-column>
              <b-table-column
                label="ສາຂາຕົ້ນທາງ"
                field="branch_branchToshipment_from_branch_id.name "
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_from_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ສາຂາປາຍທາງ"
                field="branch_branchToshipment_to_branch_id.name"
                sortable
                v-slot="props"
              >
                {{ props.row.branch_branchToshipment_to_branch_id.name }}
              </b-table-column>
              <b-table-column
                label="ຄ່າຝາກ"
                field="amount"
                sortable
                v-slot="props"
              >
                {{ props.row.amount | formatnumber }}
              </b-table-column>
              <b-table-column label="COD" sortable v-slot="props">
                {{ formattedCod(props.row) }}
              </b-table-column>
              <b-table-column
                label="ສະຖານະ"
                field="status"
                sortable
                v-slot="props"
                cell-class="has-text-centered"
              >
                <shipment-status :status="props.row.status" />
              </b-table-column>

              <b-table-column
                label="ໝາຍເຫດ"
                field="codremark"
                v-slot="props"
                class="is-size-7"
              >
                {{ props.row.codremark }}
              </b-table-column>

              <empty-table
                v-if="codDELIVERED.length == 0"
                :isLoading="isLoading"
              />
            </b-table>
          </b-tab-item>
        </b-tabs>
      </b-tab-item>
      <b-tab-item>
        <template #header>
          <b-icon icon="grid"></b-icon>
          <span>
            ກຸ່ມບິນ <b-tag rounded> {{ batchs.length }} </b-tag>
          </span>
        </template>

        <b-table
          :loading="isLoading"
          :paginated="perPage < batchs.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :narrowed="true" class="is-narrow is-size-7" style="white-space: nowrap;"
          :data="batchs"
          :current-page="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index * currentPage + 1 }}
          </b-table-column>
          <b-table-column
            label="ວັນທີ"
            field="created_date"
            sortable
            v-slot="props"
          >
            {{ props.row.created_date | datetime }}
          </b-table-column>
          <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
            <router-link :to="'/batchshipment/' + props.row.id">
              {{ props.row.code }}
            </router-link>
          </b-table-column>
          <b-table-column
            label="ຄ່າຝາກ ກີບ"
            field="amount"
            sortable
            v-slot="props"
          >
            {{ _.sumBy(props.row.shipment, "amount") | formatnumber }}
          </b-table-column>
          <b-table-column
            label="ຄ່າຝາກ ບາດ"
            field="amount_thb"
            sortable
            v-slot="props"
          >
            {{ _.sumBy(props.row.shipment, "amount_thb") | formatnumber }}
          </b-table-column>
          <b-table-column
            label="ໝາຍເຫດ"
            field="codremark"
            v-slot="props"
            class="is-size-7"
          >
            {{ props.row.codremark }}
          </b-table-column>

          <empty-table v-if="batchs.length == 0" :isLoading="isLoading" />
        </b-table>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Axios from "axios";
import { get } from "vuex-pathify";
import helper from "@/helper";
//import CardComponent from "@/components/CardComponent";
import ShipmentStatus from "../components/ShipmentStatus.vue";
import EmptyTable from "@/components/EmptyTable";
import moment from "moment";
import store from "@/store";
import XLSX from "xlsx";

export default {
  name: "CustomersInformation",
  components: { EmptyTable, ShipmentStatus },
  computed: {
    user: get("user"),
    shipmentStatuses: get("shipmentStatuses"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      isLoading: false,
      title: "ຂໍ້ມູນລູກຄ້າ",
      id: null,
      countShipment: 0,
      countCOD: 0,
      models: { district: { name: "", province: { name: "" } } },
      shipmentCREATED: [],
      shipmentSENDTED: [],
      shipmentDELIVERED: [],
      codCREATED: [],
      codSENDTED: [],
      codDELIVERED: [],
      batchs: [],
      fromdate: new Date(),
      todate: new Date(),
      userImage: "#",
    };
  },
  mounted() {
    this.id = this.$route.params["id"];
    this.fromdate = new Date();
    this.fromdate.setDate(new Date().getDate() - 30);
    this.getCustomerInfo(false);
    this.getBatchs();
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
    refreshData() {
      this.getCustomerInfo(true);
    },
    getBatchs() {
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      const url = `shipments/getbatchs/${this.id}/${fdate}/${tdate}`;
      Axios.get(url).then((res) => {
        console.log(res);
        this.batchs = res;
      });
    },
    getCustomerInfo(withDate) {
      if (!this.id) return;
      this.isLoading = true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      const dateRang = withDate ? { fromdate: fdate, todate: tdate } : {};

      this.shipmentCREATED = [];
      this.shipmentSENDTED = [];
      this.shipmentDELIVERED = [];
      this.codCREATED = [];
      this.codSENDTED = [];
      this.codDELIVERED = [];
      this.models ={ district: { name: "", province: { name: "" } } };
      this.countShipment= 0,
      this.countCOD= 0,

      Axios.get(`customers/info/${this.id}`, { params: dateRang }).then((r) => {
        //console.log(r);
        this.isLoading = false;
        if (r.shipment && r.shipment.length > 0) {
          r.shipment.forEach((i) => {
            const lak = i.cod.filter((e) => e.ccy === "LAK");
            const thb = i.cod.filter((e) => e.ccy === "THB");
            const usd = i.cod.filter((e) => e.ccy === "USD");
            i["codlak"] = lak.length > 0 ? lak[0].amount : 0;
            i["codthb"] = thb.length > 0 ? thb[0].amount : 0;
            i["codusd"] = usd.length > 0 ? usd[0].amount : 0;
            i["codremark"] = i.is_receiver_pay
              ? "ຄ່າຝາກເກັບປາຍທາງ" + (i.is_fee_in_cod ? ",ຫັກຈາກCOD" : "")
              : "";
          });
        }
        this.models = r;
        this.userImage = r.referImage ? r.referImage : "";
        if (this.models.shipment && this.models.shipment.length > 0) {
          this.countShipment = this.models.shipment.filter(
            (e) => !e.is_cod
          ).length;
          this.countCOD = this.models.shipment.filter((e) => e.is_cod).length;

          this.shipmentCREATED = this.models.shipment.filter(
            (e) =>
              !e.is_cod &&
              (e.status === helper.SHIPMENT_STATUS.INFO_CREATED ||
                e.status === helper.SHIPMENT_STATUS.ARRRIVED_SOURCE_BRANCH)
          );
          this.shipmentSENDTED = this.models.shipment.filter(
            (e) =>
              !e.is_cod &&
              (e.status ===
                helper.SHIPMENT_STATUS.ON_THE_WAY_TO_DESTINATION_BRANCH ||
                e.status ===
                  helper.SHIPMENT_STATUS.ARRRIVED_DESTINATION_BRANCH ||
                e.status ===
                  helper.SHIPMENT_STATUS.ON_THE_WAY_TO_DELIVER_CUSTOMER)
          );
          this.shipmentDELIVERED = this.models.shipment.filter(
            (e) => !e.is_cod && e.status === helper.SHIPMENT_STATUS.DELIVERED
          );

          this.codCREATED = this.models.shipment.filter(
            (e) =>
              e.is_cod &&
              (e.status === helper.SHIPMENT_STATUS.INFO_CREATED ||
                e.status === helper.SHIPMENT_STATUS.ARRRIVED_SOURCE_BRANCH)
          );
          this.codSENDTED = this.models.shipment.filter(
            (e) =>
              e.is_cod &&
              (e.status ===
                helper.SHIPMENT_STATUS.ON_THE_WAY_TO_DESTINATION_BRANCH ||
                e.status ===
                  helper.SHIPMENT_STATUS.ARRRIVED_DESTINATION_BRANCH ||
                e.status ===
                  helper.SHIPMENT_STATUS.ON_THE_WAY_TO_DELIVER_CUSTOMER)
          );
          this.codDELIVERED = this.models.shipment.filter(
            (e) => e.is_cod && e.status === helper.SHIPMENT_STATUS.DELIVERED
          );
        }
      });
    },
    formattedCod(shipment) {
      if (!shipment) return "-";
      const amount = this.$options.filters.formatnumber(
        shipment.codthb > 0 ? shipment.codthb : shipment.codlak
      );
      const ccy = shipment.codthb > 0 ? "฿" : "₭";
      return `${ccy} ${amount}`;
    },
    getUserBranchInfo() {
      return store.getters.getUserIdBranchInfo;
    },
    generateExcelFile() {
      if (this.models.shipment.length < 1){
        //console.log('Shipment data <1');
        this.$buefy.toast.open({
                    duration: 5000,
                    message: `ບໍ່ມີຂໍ້ມູນ <b>!</b> `,
                    position: 'is-top',
                    type: 'is-danger'
                });
        return;
      } 

      //console.log(`Shipment data: ${this.models.shipment.length}`);

      //Create workBook
      const workBook = XLSX.utils.book_new();
      /* worksheet  data */
      if (this.models.id) {
        const ws_data_sheet = [];
        ws_data_sheet.push(["id", this.models.id]);
        ws_data_sheet.push(["ລະຫັດ", this.models.code]);
        ws_data_sheet.push(["ຊື່", this.models.name]);
        ws_data_sheet.push(["ເບີໂທ", this.models.tel]);
        ws_data_sheet.push(["ບ້ານ", this.models.village]);
        ws_data_sheet.push(["ເມືອງ", this.models.district == null ? "-":   this.models.district.name]);
        ws_data_sheet.push(["ແຂວງ", this.models.district.province == null? "-":this.models.district.province.name]);

        /* create worksheet */
        const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet);
        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(workBook, ws, `ຂໍມູນລູກຄ້າ`);
      }
      const header = [
        "ວັນທີ",
        "ລະຫັດເຄື່ອງ",
        "ຜູ້ຮັບ",
        "ເບີຜູ້ຮັບ",
        "ສາຂາຕົ້ນທາງ",
        "ສາຂາປາຍທາງ",
        "ຄ່າຝາກ",
        "COD ກີບ",
        "COD ບາດ",
        "ສະຖານະ",
        "ໝາຍເຫດ",
      ];

      for (const sheet of [
        { sheetName: "ເຄື່ອງກຳລັງສ້າງ", data: this.shipmentCREATED },
        { sheetName: "ເຄື່ອງກຳລັງຈັດສົ່ງ", data: this.shipmentSENDTED },
        { sheetName: "ເຄື່ອງຈັດສົ່ງສຳເລັດ", data: this.shipmentDELIVERED },
        { sheetName: "COD ກຳລັງສ້າງ", data: this.codCREATED },
        { sheetName: "COD ກຳລັງຈັດສົ່ງ", data: this.codSENDTED },
        { sheetName: "COD ຈັດສົ່ງສຳເລັດ", data: this.codDELIVERED },
      ]) {
        if (sheet.data.length > 0) {
          const ws_data_sheet = [];
          ws_data_sheet.push(header);
          for (const item of sheet.data) {
            const codAmountlak = item.codthb > 0 ? 0 : item.codlak;
            const codAmountthb = item.codthb > 0 ? item.codthb : 0;
            ws_data_sheet.push([
              moment(item.created_date).format("YYYY-MM-DD HH:mm:ss"),
              item.code,
              item.receivername,
              item.receivertel,
              item.branch_branchToshipment_from_branch_id.name,
              item.branch_branchToshipment_to_branch_id.name,
              item.amount,
              codAmountlak,
              codAmountthb,
              this.shipmentStatuses[item.status].label,
              item.codremark,
            ]);
          }
          /* create worksheet */
          const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet);
          /* Add the worksheet to the workbook */
          XLSX.utils.book_append_sheet(workBook, ws, `${sheet.sheetName}`);
        }
      }

      /* Write workbook to file */
      const _date = moment(new Date()).format("YYYY_MM_DD_HHmmss");
      const _ub = this.getUserBranchInfo();
      XLSX.writeFile(
        workBook,
        `CUSTOMER_DETAIL_${this.models.id}_${_ub}_FILE_${_date}.xlsx`
      );
    },
  },
};
</script>
